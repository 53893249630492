/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import Nav from "../components/nav"
import "foundation-sites/dist/css/foundation.min.css"
import "./layout.scss"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Layout = props => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      wpMenu(name: { eq: "Main Menu" }) {
        menuItems {
          nodes {
            id
            label
            childItems {
              nodes {
                id
                label
                url
              }
            }
          }
        }
      }
      headerButton: wpMenu(name: { eq: "Header Top Buttons" }) {
        menuItems {
          nodes {
            id
            label
            url
          }
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <meta
          name="ahrefs-site-verification"
          content="b69bf083af87e50dad861bc75412277287310de3517e4f9ca69a325e8a590bc2"
        />
        <script
          id="mcjs"
          defer
          dangerouslySetInnerHTML={{
            __html: `
              !function(c,h,i,m,p){
                m=c.createElement(h),p=c.getElementsByTagName(h)[0],
                m.async=1,m.src=i,p.parentNode.insertBefore(m,p)
              }(document,"script","https://chimpstatic.com/mcjs-connected/js/users/9189cc74acefd08abbc393341/89fd7d60f5101d4fa7a50e5b9.js");
            `,
          }}
        />
      </Helmet>
      <div
        style={{
          margin: `0 auto`,
        }}
      >
        {data && <Nav nav={data.wpMenu} headerBtn={data.headerButton} />}
        <main>{props.children}</main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
