/** @jsx jsx */
import { jsx } from "theme-ui"
import { useRef, useEffect } from "react"
import { Link } from "gatsby"
import { FaChevronRight } from "react-icons/fa"

import Logo from "../images/logo.svg"

const win = typeof window !== "undefined" && window

const Nav = ({ nav, headerBtn }) => {
  const inputEl = useRef(null)
  const line1 = useRef(null)
  const line2 = useRef(null)
  const mobileNav = useRef(null)
  const heroRef = useRef(null)

  useEffect(() => {
    stickyNav()
    window.addEventListener("scroll", stickyNav)

    // Add heroRef
    const heroElement = document.querySelector(".hero")
    heroRef.current = heroElement

    return function cleanup() {
      window.removeEventListener("scroll", stickyNav)
    }
  })

  const stickyNav = () => {
    const scrollTop = win.pageYOffset

    // Sticky Nav
    if (scrollTop > 0) {
      inputEl.current.classList.add("is-sticky")
      if (heroRef.current) {
        heroRef.current.style.marginTop = "0"
      }
    } else if (scrollTop <= 0) {
      inputEl.current.classList.remove("is-sticky")
      if (heroRef.current) {
        heroRef.current.style.marginTop = "140px"
      }
    }
  }

  const toggleMenu = () => {
    line1.current.classList.toggle("line1-active")
    line2.current.classList.toggle("line2-active")
    mobileNav.current.classList.toggle("mobile-nav-active")
  }

  const removeMenu = e => {
    e.preventDefault()
    line1.current.classList.remove("line1-active")
    line2.current.classList.remove("line2-active")
    mobileNav.current.classList.remove("mobile-nav-active")
  }

  let navItem = nav.menuItems?.nodes?.filter(nav => {
    if (nav?.childItems?.nodes?.length > 0) {
      return nav?.childItems?.nodes
    }
  })

  return (
    <div className="nav" ref={inputEl}>
      <div className="nav-holder">
        <div className="menu-left">
          <a href="/">
            <img
              className="nav__logo"
              src={Logo}
              width="100"
              height="105"
              alt="Logo"
            ></img>
          </a>
        </div>
        <div className="menu-right">
          <ul className="menu-right--list">
            {headerBtn?.menuItems?.nodes?.map(e => {
              return (
                <li key={e.id}>
                  <Link to={e.url}>
                    <button className="header-btn">{e.label}</button>
                  </Link>
                </li>
              )
            })}
          </ul>

          <div className="hamburger" onClick={toggleMenu}>
            <div ref={line1} className="line1"></div>
            <div ref={line2} className="line2"></div>
          </div>

          <ul className="mobile-nav" ref={mobileNav}>
            {navItem?.map(item => {
              return (
                <li key={item?.id}>
                  <h3>{item?.label}</h3>
                  {item.childItems?.nodes?.map(childs => {
                    return (
                      <span key={childs?.id}>
                        <FaChevronRight />
                        {childs?.url?.startsWith("http") ? (
                          <a
                            href={childs?.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {childs?.label}
                          </a>
                        ) : (
                          <Link to={childs?.url}>{childs?.label}</Link>
                        )}
                      </span>
                    )
                  })}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Nav
